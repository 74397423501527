import { ServiceCall } from "support/network/ServiceCallAction"
import { prepareHeadersFromState } from "../HeadersUtils"
import { GET_All_USER_BENCH_DATA_LIST_FAILURE, GET_All_USER_BENCH_DATA_LIST_STARTED, GET_All_USER_BENCH_DATA_LIST_SUCCESS, GET_BENCH_DATA_LIST_FAILURE, GET_BENCH_DATA_LIST_STARTED, GET_BENCH_DATA_LIST_SUCCESS, GET_BENCH_SEARCH_DATA_LIST_FAILURE, GET_BENCH_SEARCH_DATA_LIST_STARTED, GET_BENCH_SEARCH_DATA_LIST_SUCCESS, GET_BENCH_USER_DISPLAY_PROFILE_FAILURE, GET_BENCH_USER_DISPLAY_PROFILE_STARTED, GET_BENCH_USER_DISPLAY_PROFILE_SUCCESS, GET_BENCH_USER_UPDATE_PROFILE_FAILURE, GET_BENCH_USER_UPDATE_PROFILE_STARTED, GET_BENCH_USER_UPDATE_PROFILE_SUCCESS } from "actions/sync/bench/Constants"


const setBenchUserEndpoint = ({
  baseUrl,
  filterDataWithOrderArray,
  filterDataWithRolesArray ,
  filterDataWithSkillArray,
  filterDataWithCommunitiesArray,
}) => {
  let url = baseUrl
    url += `?${encodeURIComponent('order')}=${filterDataWithOrderArray}`
    url += `&${encodeURIComponent('roles')}=${filterDataWithRolesArray}`
    url += `&${encodeURIComponent('skills')}=${filterDataWithSkillArray}`
    url += `&${encodeURIComponent('communities')}=${filterDataWithCommunitiesArray}`
  return url
}

function setBenchUpdateProfilteEndpoint({myEmail,company,yourDivision,yourjobTitle,yourMarket,jointeam,skills,roles,communities,textbox}) {
  // console.log("🚀 ~ setBenchUpdateProfilteEndpoint ~ skills:", skills)
  return `${encodeURIComponent('email')}=${encodeURIComponent(myEmail)}&${encodeURIComponent('role')}=${encodeURIComponent(roles)}&${encodeURIComponent('skills')}=${encodeURIComponent(skills)}&${encodeURIComponent('availabilityInTeam')}=${encodeURIComponent(jointeam)}&${encodeURIComponent('company')}=${encodeURIComponent(company)}&${encodeURIComponent('market')}=${encodeURIComponent(yourMarket)}&${encodeURIComponent('division')}=${encodeURIComponent(yourDivision)}&${encodeURIComponent('jobTitle')}=${encodeURIComponent(yourjobTitle)}&${encodeURIComponent('shortInfo')}=${encodeURIComponent(textbox)}`
          // ${encodeURIComponent('shortinfor')}=${encodeURIComponent(shortinfo)}`
              // ${encodeURIComponent('communities')}=${encodeURIComponent(communities)}&
}




export const userBenchDropdwonDefaultDataURL = 'api/v1/user/bench'
export const userBenchSearchURL = 'api/v1/user/benchUsers?search=##searchName##'
export const allBenchUserListURL = 'api/v1/user/bench/userfilter'
export const benchUserDispalyProfileURL = 'api/v1/user/bench/userprofile/display/##email##'
export const benchUserUpdateProfileURL = 'api/v1/user/bench/userprofile/update/##email##'
// export const allBenchUserListURL = 'api/v1/user/bench/userfilter/ASC/ALL/ALL/ALL/all'


export function getBenchDropdowndatalist() {
    const apiCall = new ServiceCall()
    apiCall.startedReqType = GET_BENCH_DATA_LIST_STARTED
    apiCall.successReqType = GET_BENCH_DATA_LIST_SUCCESS
    apiCall.failureReqType = GET_BENCH_DATA_LIST_FAILURE
    apiCall.setEndpointFromState = () =>
      `${userBenchDropdwonDefaultDataURL}`
    apiCall.method = 'GET'
    apiCall.getHeadersFromState = prepareHeadersFromState
    // apiCall.transformResponseDataWithState = transformGetChallengeByIdService
    apiCall.auth = true
    return apiCall.getAction()
  }

export function getAllBenchUserDataList({ 
  filterDataWithOrderArray='ASC',
  filterDataWithRolesArray="ALL",
  filterDataWithSkillArray='ALL',
  filterDataWithCommunitiesArray="ALL"}) {
    console.log("filterDataWithRolesArray defaul ", filterDataWithOrderArray)
    const apiCall = new ServiceCall()
    apiCall.startedReqType = GET_All_USER_BENCH_DATA_LIST_STARTED
    apiCall.successReqType = GET_All_USER_BENCH_DATA_LIST_SUCCESS
    apiCall.failureReqType = GET_All_USER_BENCH_DATA_LIST_FAILURE
    apiCall.setEndpointFromState = () => 
    setBenchUserEndpoint({
      baseUrl: allBenchUserListURL,
      filterDataWithOrderArray,
      filterDataWithRolesArray,
      filterDataWithSkillArray,
      filterDataWithCommunitiesArray
    })
    apiCall.method = 'GET'
    apiCall.getHeadersFromState = prepareHeadersFromState
    // apiCall.transformResponseDataWithState = transformGetChallengeByIdService
    apiCall.auth = true
    return apiCall.getAction()
  }

  export function getSearchApiBenchDatalist(userSearch) {
    const apiCall = new ServiceCall()
    apiCall.startedReqType = GET_BENCH_SEARCH_DATA_LIST_STARTED
    apiCall.successReqType = GET_BENCH_SEARCH_DATA_LIST_SUCCESS
    apiCall.failureReqType = GET_BENCH_SEARCH_DATA_LIST_FAILURE
    apiCall.setEndpointFromState = () => userBenchSearchURL.replace('##searchName##', userSearch)
    apiCall.method = 'GET'
    apiCall.getHeadersFromState = prepareHeadersFromState
    // apiCall.transformResponseDataWithState = transformGetChallengeByIdService
    apiCall.auth = true
    return apiCall.getAction()
  }

  export function getBenchUserDisplayProfile(userEmail) {
    const apiCall = new ServiceCall()
    apiCall.startedReqType = GET_BENCH_USER_DISPLAY_PROFILE_STARTED
    apiCall.successReqType = GET_BENCH_USER_DISPLAY_PROFILE_SUCCESS
    apiCall.failureReqType = GET_BENCH_USER_DISPLAY_PROFILE_FAILURE
    apiCall.setEndpointFromState = () => benchUserDispalyProfileURL.replace('##email##', userEmail)
    apiCall.method = 'GET'
    apiCall.getHeadersFromState = prepareHeadersFromState
    // apiCall.transformResponseDataWithState = transformGetChallengeByIdService
    apiCall.auth = true
    return apiCall.getAction()
  }

  export function getBenchUserUpdateProfile(myEmail,userUpdateData,onSuccess,onFailure) {
    console.log("🚀 ~ UPDATE ~ UPDATE:", userUpdateData)
    
    const {company,yourDivision,yourjobTitle,yourMarket,jointeam,skills,roles,communities,textbox} = userUpdateData 
    // console.log("🚀 ~ getBenchUserUpdateProfile ~ roles:", roles)
    // console.log("🚀 ~ getBenchUserUpdateProfile ~ skills:", skills)
    
    const apiCall = new ServiceCall()
    apiCall.startedReqType = GET_BENCH_USER_UPDATE_PROFILE_STARTED
    apiCall.successReqType = GET_BENCH_USER_UPDATE_PROFILE_SUCCESS
    apiCall.failureReqType = GET_BENCH_USER_UPDATE_PROFILE_FAILURE
    apiCall.setEndpointFromState = () => benchUserUpdateProfileURL.replace('##email##', myEmail)
    apiCall.method = 'POST'
    apiCall.setBodyFromState= ()=> setBenchUpdateProfilteEndpoint({myEmail,company,yourDivision,yourjobTitle,yourMarket,jointeam,skills,roles,communities,textbox})
    apiCall.getHeadersFromState = prepareHeadersFromState
    apiCall.onSuccess = onSuccess
    apiCall.onFailure = onFailure
    // apiCall.transformResponseDataWithState = transformGetChallengeByIdService
    apiCall.auth = true
    return apiCall.getAction()
  }


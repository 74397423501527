
import React, { useEffect, useState } from 'react';
import { withStyles, styled } from '@material-ui/core/styles'
import styles from './View.Style'
import Select, { components } from 'react-select';




const Option = (props) => {
    // console.log("🚀 ~ Option ~ props:", props)
    const {isSelected,label} = props
    return (
      <components.Option {...props}>
        <input
            className='checkboxStyle'
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
        />
        {label}
      </components.Option>
    );
  };
  
  const MultiSelectWithCheckbox = ({ options, register, name, setValue , initialValue} = {}) => {
    const [selectedOptions, setSelectedOptions] = useState( initialValue || []);
    console.log("🚀 ~ MultiSelectWithCheckbox ~ initialValue:", selectedOptions)

    useEffect(() => {
        register(name);
      }, [register, name]);
    
      useEffect(() => {
        setValue(name, selectedOptions.map(option => option.value));
      }, [selectedOptions, setValue, name]);

      useEffect(() => {
        if (initialValue) {
          setSelectedOptions(initialValue);
        }
      }, [initialValue]);

    const handleChange = (selected) => {
      setSelectedOptions(selected);
    };
    
// console.log("ALIIIIÌ", options[0]?.value)

    return (
        <div>
            <Select
                options={options}
                defaultValue='ALL'
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option }}
                onChange={handleChange}
                value={selectedOptions}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        marginBottom:'1rem',
                        height: '3rem', // Increase the height
                        fontSize: '1rem', // Increase the font size
                        borderColor: 'rgba(0, 0, 0, 0.23)', // Change the border color
                        // Add more custom styles as needed
                      }),
                    // container: (base) => ({
                    //     ...base,
                    //     backgroundColor:'#ccc',
                    //     padding: 0,
                    //   }),
                    // multiValueLabel: (base) => ({
                    //   ...base,
                    //   backgroundColor:'#e60000',
                    //   color: 'white',
                    // }),
                    // multiValueRemove: (base) => ({
                    //     ...base,
                    //     // border: `1px dotted #e60000`,
                    //     backgroundColor:'#e60000',
                    //     color:'#fff'
                    //   }),
                  }}
            />
            <input
                type="hidden"
                value={selectedOptions.map(option => option.value)}
                {...register(name)}
            />
        </div>
    );
  };
  



export default withStyles(styles)(MultiSelectWithCheckbox)
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable no-multi-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import { Title } from 'components_newui/Title/Title'
import { BenchUserCard } from './BenchUserCard'
import MultiSelectFilter from '../../components/filter_component/components/multi_select_filter/View'
import SingleSelectFilter from '../../components/filter_component/components/select_filter/View'
import * as pages from '../../common_constants/Pages'
import NavigationService from '../../support/navigation/NavigationService'
import Input from './Input'
import { Link } from 'react-router-dom'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import Select, { components,ValueContainerProps } from "react-select";

// const skillsDataList = ['html','css','java','spring','react','javascript']
const orderByDataList = [{ value: 'ASC', label: 'ASC' },{ value: 'DSC', label: 'DSC' }]
// const RollsDataList = ['Frontend Developrt','Backend Devloper', 'UX Designer']
// const CommunitiesDataList= ['IN','UK','GR','EG']



const TheBench = ({ myEmail, getSearchApiBenchDatalistFn, getBenchDropdownDatalistFn, getAllBenchUserDataList, classes, benchDropdownList, benchSearchUserList, benchUser, BenchUserLoading, BenchDropdownListLoading, BenchSearchListLoading }) => {
  // console.log("🚀 ~ TheBench ~ benchDropdownList:", benchDropdownList,benchSearchUserList,benchUser,BenchUserLoading,BenchDropdownListLoading,BenchSearchListLoading)

  // const [apiUsers, setApiUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selected, setSelected] = React.useState([]);

  const [benchFilter, setBenchFilter] = useState({
    orderDataValue: [{ value: 'ASC', label: 'ASC' }],
    role:[{ value: 'ALL', label: 'ALL' }],
    skill: [{ value: 'ALL', label: 'ALL' }],
    communities: [{ value: 'ALL', label: 'ALL' }],
  })
  // console.log("🚀 ~ TheBench ~ benchFilter:", benchFilter)

  // const roleLabels = (benchFilter.role || [] )?.map(role => role.value);
  // console.log("🚀 ~ TheBench ~ roleLabels:", roleLabels)

  React.useEffect(() => {
    if (searchQuery.length > 3) {
      setFilteredUsers(benchSearchUserList.data)
    } else {
      setFilteredUsers(benchUser)
    }
  }, [searchQuery, benchUser, benchSearchUserList])


  React.useEffect(() => {
    if (searchQuery?.length > 3) {
      getSearchApiBenchDatalistFn(searchQuery)
    }
  }, [searchQuery])

  React.useEffect(() => {
    getBenchDropdownDatalistFn()
  }, [getBenchDropdownDatalistFn])


  React.useEffect(() => {
    getAllBenchUserDataList(benchFilter)
  }, [benchFilter])


  const applyBenchRollFilterFn = (e) => {
      // console.log("🚀 ~ applyBenchRollFilterFn ~ e:", e)
      // const roleArray = e?.map((option)=>option?.value)
      // console.log("🚀 ~ applyBenchRollFilterFn ~ roleArray:", roleArray)
      // // const {value} = e
      // // console.log("rolllll=======>", e?.map((option)=>( console.log("option====look",option.value))) )
      // // console.log("🚀 ~ applyBenchRollFilterFn ~ value:", value)
      // // e?.map((option)=>( { value:option, label: option })) ;
      // const  roleValueOnChange = e?.map((option)=>(option.value))
      // console.log("🚀 ~ applyBenchRollFilterFn ~ roleValueOnChange:", roleValueOnChange)
    


// Extract selected values

// const roleArray = e

//     .filter(option => typeof option === 'object' && option !== null) // Ensure option is an object
//     .map((option) => option.value)
//     .filter(Boolean); // Filter out undefined values
//     // console.log("🚀 ~ applyBenchRollFilterFn ~ roleArray:", roleArray)
//        // Check for undefined values
//     if (roleArray.includes(undefined)) {
//       console.error("Error: Undefined value detected in roleArray");
//     }

    setBenchFilter(previousState => ({
      ...previousState,
      role:e,
    }));
    setSearchQuery('')

  }

  const applyBenchCommunitiesFilterFn = (e) => {
    // const { value } = e.target;
    setBenchFilter(previousState => ({
      ...previousState,
      communities: e,
    }));
    setSearchQuery('')
  }

  const applyBenchOrderFilterFn = (e) => {
    // const { value } = e.target;
    setBenchFilter(previousState => ({
      ...previousState,
      orderDataValue: e,
    }));
    setSearchQuery('')
  }

  const applyBenchSkillsFilterFn = (e) => {
    // const { value } = e.target;
    setBenchFilter(previousState => ({
      ...previousState,
      skill: e,
    }));
    setSearchQuery('')
  }

  const clearBenchSkillsFilterfn = () => {
    setBenchFilter(previousState => ({
      ...previousState,
      skill: [],
    }));
    setSearchQuery('')
  }

  const checkFunction = (value = 0, element) => {
    console.log("Element:", element, "Value:", value);
    return value.indexOf(element) > -1
  }
  const idCheckFunction = (value, element) => {
    console.log("element---", element, value)
    return value?.indexOf(element?.name) > -1
  }


  const orderArray = benchDropdownList.map(item => item.order);
  const validOrderArray = [...new Set(orderArray.filter(value => value !== undefined && value !== null && value !== ''))];
  const rolesArray = benchDropdownList.map(item => item.roles);
  const validRolesArray = [...new Set(rolesArray.filter(value => value !== undefined && value !== null && value !== ''))]
  ?.map((option)=>( { value:option, label: option })) ;
  const SkillsArray = benchDropdownList.map(item => item.skills);
  const validSkillsArray = [...new Set(SkillsArray.filter(value => value !== undefined && value !== null && value !== ''))]
  ?.map((option)=>( { value:option, label: option })) ;
  const communitiesArray = benchDropdownList.map(item => item.communities);
  const validCommunitiesArray = [...new Set(communitiesArray.filter(value => value !== undefined && value !== null && value !== ''))]
  ?.map((option)=>( { value:option, label: option })) ;


 const optionsCommunities = [
        { value: 'ALL', label: 'ALL'},
        ...validCommunitiesArray
    ]


  // console.log("🚀 ~ TheBench ~ validRolesArray:", validRolesArray, benchFilter.role)



  // search

  // const filterItems = (searchTerm) => { 
  //   const filteredItems = apiUsers.filter((user) =>
  //     user.firstName.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //    searchTerm ? setFilteredUsers(filteredItems) :  setFilteredUsers(apiUsers) ;
  // }


  // const SearchItemsList = ({items}) => {
  //   return (
  //     <>
  //       {/* replace filteredUsers with items*/}
  //       {items.length === 0
  //         ? <p>No users found</p>
  //         : <ul>
  //           {items.map(item => <BenchUserCard user={item} />)}
  //         </ul>
  //       }
  //     </>
  //   )
  // }


  // const ShowSearchBenchResult = ()=>{
  //  return(
  //   <>
  //     {loading && <p>Loading...</p>}
  //     {error && <p>There was an error loading the users</p>}
  //     {!loading && !error && <ItemList items={filteredUsers} />}
  //   </>
  //  ) 

  // }

  const handleSearchBenchUser = (e) => {
    const searchTerm = e.target.value;
    if(searchQuery.length > 3 ) {
      setBenchFilter({
        orderDataValue: [{ value: 'ASC', label: 'ASC' }],
        role:[{ value: 'ALL', label: 'ALL' }],
        skill: [{ value: 'ALL', label: 'ALL' }],
        communities: [{ value: 'ALL', label: 'ALL' }],
      })
    }
    setSearchQuery(searchTerm)
    
    
    // filter the items using the apiUsers state
    // const handleSearchfilteredItems = benchUser.filter((user) =>
    //   user.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    // );

    // return searchQuery?.length > 3 ? setFilteredUsers(benchSearchUserList) : setFilteredUsers(benchUser)
  }


  // console.log("ben----00000", filteredUsers)


  const ROUTES = NavigationService.getRoutes()
  const benchProfileUserMePath = ROUTES[pages.BENCHPROFILE].path.replace(":email", myEmail);




  return (
    <NxtCircularLoader isLoading={BenchUserLoading || BenchUserLoading || BenchSearchListLoading}>
    <div className={classes.container}>


      <div className={classes.alignHeading}>
        {/* <Title title='The Bench' /> */}
        <Link to={{ pathname: `${benchProfileUserMePath}` }} className={classes.viewlink} >Update Bench Profile</Link>
      </div>
      <div className='wrp2col'>
        <div className={classes.bench_filter}>

          <div className='filter1' >
            {/* <SingleSelectFilter
              label='Sort by Order'
              value={benchFilter.orderDataValue}
              onChange={applyBenchOrderFilterFn}
              items={orderByDataList}

            /> */}

              <MultiDropdownComponent
                  optionListData={orderByDataList}
                  onChangeHandler={applyBenchOrderFilterFn}
                  optionValue={benchFilter.orderDataValue}
                  label='Sort by Order'
                  enableMultiSelect={false}
              />

          </div>
          <div className='filter2'>
              <MultiDropdownComponent
                  optionListData={validRolesArray}
                  onChangeHandler={applyBenchRollFilterFn}
                  optionValue={benchFilter.role}
                  label='Select Roles'
                  enableMultiSelect
              />
          </div>
          <div className='filter3'>
              <MultiDropdownComponent
                  optionListData={validSkillsArray}
                  onChangeHandler={applyBenchSkillsFilterFn}
                  optionValue={benchFilter.skill}
                  label='Select Skills'
                  enableMultiSelect
              />
          </div>

          <div className='filter4 '>
         
              <MultiDropdownComponent
                  optionListData={optionsCommunities}
                  onChangeHandler={applyBenchCommunitiesFilterFn}
                  optionValue={benchFilter.communities}
                  label='Select Communities'
                  enableMultiSelect={false}
              />

          </div>
        </div>
        <div className='blockright'>
          <div className='fliter5'>
            <div className={classes.search_text_div}>
              <label>Bench search user</label>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchBenchUser}
                placeholder='Search bench user'
              />
            </div>
          </div>
        </div>
      </div>
     

      <main>
        <div className={classes.wrp_bench}>
          {/* <ShowSearchBenchResult /> */}



          {
            filteredUsers?.length === 0 ? <p>No users found</p> : filteredUsers?.map((user) => (
              <BenchUserCard
                key={user.id}
                classes={classes}
                user={user}
              />
            ))
          }
        </div>
      </main>

    </div>
    </NxtCircularLoader>
  )
}

export default withStyles(styles)(TheBench)



const ValueContainer = ({ children, ...props }) => {
  let [filterDefaultValue] = children;
  // const valstr = values?.filter(item=>item.props.children)
  if (Array.isArray(filterDefaultValue)) {
       filterDefaultValue = filterDefaultValue.length > 1 ? filterDefaultValue.filter(item =>item.props?.children!== 'ALL') :  filterDefaultValue
        // console.log("🚀 ~ filterDefaultValue:-===", filterDefaultValue)
      filterDefaultValue = filterDefaultValue.length > 1 ? `${filterDefaultValue.length} selected` : filterDefaultValue
     
      // filterDefaultValue = `${filterDefaultValue.length} selected` ;
    // values = `${values.length} selected` ;
  }
  return (
  
    <components.ValueContainer {...props}>
      {filterDefaultValue}
     {/* {values} */}
    </components.ValueContainer>
  );
};

const Option = (props) => {
  const {isSelected,label,value,children} = props
  return (
    <components.Option {...props}>
      <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
      />
      {label}
    </components.Option>
  );
};


const MultiDropdownComponent = (props)=>{
    const {optionListData,onChangeHandler,optionValue,label,enableMultiSelect} = props || {}

  return(
    <div>
      <label htmlFor={label}>{label}</label>
      <Select
        options={optionListData}
        isMulti={enableMultiSelect}
        isSearchable
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={enableMultiSelect ? { Option,ValueContainer, ClearIndicator:'null',  MultiValueRemove:'null' }  : null }
        value={optionValue}
        onChange={onChangeHandler}
        className="vf-multiselect"
        styles={{
          control: (provided) => ({
            ...provided,
            marginBottom: '1rem',
            borderRadius: '25px',
            width: '12rem',
            height: '3rem', // Increase the height
            fontSize: '1rem', // Increase the font size
            borderWidth:'2px',
            borderColor: '#6551FF', // Change the border color
            // Add more custom styles as needed
          }),
          multiValue: (base) => ({
            ...base,
            background: 'transparent',
          }),
        }}
      />
    </div>

  )
}
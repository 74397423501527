const styles = theme => ({
  root: {
    gridColumnStart: 2,
    padding: theme.spacing(5, 0),
  },

  BenchProfileWrp: {

    '& .benchProfileFromWrp': {
      '& h1': {
        textAlign: 'center',
        margin: '0 0 2.5rem',
        paddingBottom:'0.5rem',
        borderBottom:'2px dashed #333'
      },
      maxWidth: '800px',
      background: '#fff',
      padding: '2rem',
      'box-sizing': 'border-box',
      'margin': '2rem auto',
      'border-radius': '5px',

      '& .subheading': {
        borderBottom: '1px dashed #333',
        marginBottom: '2rem',
        '& h2': {
          fontSize: '1.125rem',
          fontFamily: "VodafoneRg-Regular",
          color: '#333',
          margin: '0 0 0.5rem',
        }
      },

    },

    '& .checkboxInline':{
      display:'flex',
      alignItems:'center',
      marginBottom:'1.5rem',
      '& label':{
        margin:'0'
      }
    },

    '& .fieldbox': {
      // display: 'block',
      '& label': {
        fontSize: '1rem',
        fontFamily: "VodafoneRg-bold",
        color: '#333',
        margin: '0 0 0.5rem',
        display: 'block'
      },
      '& select, & textarea , & input[type="text"], & input[type="email"]': {
        fontSize: '1rem',
        fontFamily: "VodafoneRg-Regular",
        color: 'currentColor',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        margin: '0 0 1rem',
        display: 'block',
        padding: '14.5px 14px;',
        minWidth: '0',
        background: 'none',
        boxSizing: 'border-box',
        letterSpacing: 'inherit',
        '-webkit-tap-highlight-color': 'transparent'
      },
     
    },
    
    '& .fieldDisable':{
      opacity:'0.5'
    },

    '& button[type="submit"]':{
        cursor:'pointer',
    
      backgroundColor:'#e60000',
      border:'1px solid #e60000',
      color:'#fff',
      // width: '100%',
      display: 'block',
      padding: '12px',
      'box-sizing': 'border-box',
      textAlign: 'center',
      fontSize: '20px',
      fontFamily: "VodafoneRg-Regular",
      borderRadius:'5px',
      transition:'all 300ms ease',
      margin:'1rem auto',
      '&:hover':{
        background:'transparent',
        border:'1px solid #e60000',
        color:'#333'
      }
    

    }

  }

})

export default styles

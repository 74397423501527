import { 
  GET_All_USER_BENCH_DATA_LIST_FAILURE, 
  GET_All_USER_BENCH_DATA_LIST_STARTED, 
  GET_All_USER_BENCH_DATA_LIST_SUCCESS, 
  GET_BENCH_DATA_LIST_FAILURE, 
  GET_BENCH_DATA_LIST_STARTED, 
  GET_BENCH_DATA_LIST_SUCCESS, 
  GET_BENCH_SEARCH_DATA_LIST_FAILURE, 
  GET_BENCH_SEARCH_DATA_LIST_STARTED, 
  GET_BENCH_SEARCH_DATA_LIST_SUCCESS, 
  GET_BENCH_USER_DISPLAY_PROFILE_FAILURE, 
  GET_BENCH_USER_DISPLAY_PROFILE_STARTED, 
  GET_BENCH_USER_DISPLAY_PROFILE_SUCCESS, 
  GET_BENCH_USER_UPDATE_PROFILE_FAILURE, 
  GET_BENCH_USER_UPDATE_PROFILE_STARTED,
  GET_BENCH_USER_UPDATE_PROFILE_SUCCESS} from "actions/sync/bench/Constants"

const initialState = {
  BenchUserLoading: false,
  BenchDropdownListLoading: false,
  BenchSearchListLoading: false,
  benchUserDisplayProfileLoading:false,
  benchUserUpdateProfileLoading:false,
  benchUser: [],
  benchSearchUserList:[],
  benchDropdownList:[],
  benchUserDisplayProfile:[],
  benchUserUpdateProfile:''
}

const benchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BENCH_DATA_LIST_STARTED:
      return {
        ...state,
        BenchDropdownListLoading: true,
      }
    case GET_BENCH_SEARCH_DATA_LIST_STARTED:
      return {
        ...state,
        BenchSearchListLoading: true,
      }
    case GET_All_USER_BENCH_DATA_LIST_STARTED:
      return {
        ...state,
        BenchUserLoading: true,
      }
    
    case GET_BENCH_DATA_LIST_SUCCESS:
      return {
        ...state,
        benchDropdownList: action.response,
        BenchDropdownListLoading: false,
      }
    case GET_All_USER_BENCH_DATA_LIST_SUCCESS: {
      return {
        ...state,
        benchUser: action.response,
        BenchUserLoading: false,
      }
    }
    case GET_BENCH_SEARCH_DATA_LIST_SUCCESS: {
      return {
        ...state,
        benchSearchUserList: action.response,
        BenchSearchListLoading: false,
      }
    }
    case GET_BENCH_USER_DISPLAY_PROFILE_STARTED:{
      return{
        ...state,
        benchUserDisplayProfileLoading:true
      }
    }
    case GET_BENCH_USER_DISPLAY_PROFILE_SUCCESS:{
      return{
        ...state,
        benchUserDisplayProfile: action.response,
        benchUserDisplayProfileLoading:false
      }
    }
    case GET_BENCH_USER_DISPLAY_PROFILE_FAILURE:{
      return{
        ...state,
        benchUserDisplayProfileLoading:false
      }
    }
    case GET_BENCH_USER_UPDATE_PROFILE_STARTED:{
      return{
        ...state,
        benchUserUpdateProfileLoading:true
      }
    }
    case GET_BENCH_USER_UPDATE_PROFILE_SUCCESS:{
      return{
        ...state,
        benchUserUpdateProfile: action.response,
        benchUserUpdateProfileLoading:false
      }
    }
    case GET_BENCH_USER_UPDATE_PROFILE_FAILURE:{
      return{
        ...state,
        benchUserUpdateProfileLoading:false
      }
    }

    case GET_All_USER_BENCH_DATA_LIST_FAILURE:
    case GET_BENCH_SEARCH_DATA_LIST_FAILURE:
    case GET_BENCH_DATA_LIST_FAILURE:
      return {
        ...state,
        BenchDropdownListLoading: false,
        BenchSearchListLoading: false,
        BenchUserLoading: false,
      }

    default:
      return state
  }
}

export default benchReducer

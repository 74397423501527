/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,no-unused-expressions, react/default-props-match-prop-types */
import { withStyles, styled } from '@material-ui/core/styles'
import styles from './View.Style'
import * as PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import search_icon from '../../img/search-icon.png'
import cancel from '../../img/remove.png'
import SelectFilter from './components/select_filter/View'
import MultiSelectFilter from './components/multi_select_filter/View'
import ideaStatusList from 'common_constants/Selects'


const FilterComponent = props => {

    const {
        classes,
        activeFilters,
        handleActiveFilters,
        ideaStatusFilter,
        types,
        serviceLineOrgs,
        search,
        setSearch,
        applySearch,
        resetSearch,
        showSearch,
        preSearched
    } = props

    // console.log('props ,dilter',props)

    const [serviceLine, setServiceLine] = useState([])
    const [type, setType] = useState([])
    const [ideaStatus, setIdeaStatus] = useState([])
    const [searched, setSearched] = useState(preSearched)
    
    // console.log("searched---,preSearched",searched,preSearched)
    
    
    const applyItemsFilter = event => {
        setType(event.target.value)
        handleActiveFilters({
            ...activeFilters,
            types: event.target.value
        })
    }

    const applyStatusFilter = event => {        
        setIdeaStatus(event.target.value)
        handleActiveFilters({
            ...activeFilters,
            ideaStatusFilter: event.target.value
        })
    }

    const applyServiceFilter = event => {
        setServiceLine(event.target.value)
        handleActiveFilters({
            ...activeFilters,
            organizationsFilter: event.target.value
        })
    }
    const clearItemsFilter = () => {
        setType([])
        handleActiveFilters({
            ...activeFilters,
            types: []
        })
    }
    const clearStatusFilter = () => {
        setIdeaStatus([])
        handleActiveFilters({
            ...activeFilters,
            ideaStatusFilter: []
        })
    }

    const clearServiceFilter = () => {
        setServiceLine([])
        handleActiveFilters({
            ...activeFilters,
            organizationsFilter: []
        })
    }

    const clearSearch = () => {
        resetSearch()
        setSearched(false)
        setSearch('')
    }

    const submitSearchIdOrTitle = () => {
        setSearched(true)
        search.length > 0 ? applySearch(search) : clearSearch()
    }


    
      const checkFunction = (value, element) => {
        return value.indexOf(element.value) > -1
      }
      const idCheckFunction = (value, element) => {
        return value.indexOf(element.id) > -1
      }

    // Make the select filter a nested component
    return(
        <div className={classes.container}>
            {showSearch ? <div className={classes.search_text_div}>
                <input className={classes.search_text_input} type="text" placeholder="Search by title" value={search}
                    onChange={event => setSearch(event.target.value)} 
                    onKeyDown={event => {if (event.key === 'Enter'){submitSearchIdOrTitle()}}} />
                <img className={classes.search_text_search_icon} src={search_icon} onClick={submitSearchIdOrTitle} alt='search icon' />
                {searched && 
                <img src={cancel} onClick={clearSearch} className={classes.cancel_search_icon} alt='clear search' />}
            </div>: <div/>}
         
            <div className={classes.select_filters_container}>
                
                {serviceLineOrgs && serviceLineOrgs.length > 0 && 
                <MultiSelectFilter label='Service line' 
                    items={serviceLineOrgs} 
                    value={serviceLine} 
                    onChange={applyServiceFilter} 
                    clearAll={clearServiceFilter}
                    checkFunction={idCheckFunction}
                />}
                
                {types && types.length > 0 && 
                <MultiSelectFilter label='Categories' 
                    items={types} 
                    value={type} 
                    onChange={applyItemsFilter} 
                    clearAll={clearItemsFilter}
                    checkFunction={idCheckFunction}
                />}
                
                {ideaStatusFilter   && ideaStatusList.length > 0 && 
                <MultiSelectFilter 
                label='Idea status' 
                value={ideaStatus} 
                onChange={applyStatusFilter} 
                items={ideaStatusList} 
                clearAll={clearStatusFilter}
                checkFunction={checkFunction}
                />}
            </div>
        </div>
    )
}

FilterComponent.defaultProps = {
    showSearch: true,
    preSearched: false
}
FilterComponent.propTypes = {
    showSearch: PropTypes.bool,
}

export default withStyles(styles)(FilterComponent)
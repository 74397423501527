import React from 'react'
import { connect } from 'react-redux'

import {
  showPopupDialog,
  showPopupErrorSnackbar,
  showPopupSuccessSnackbar,
} from 'support/popup_dialogs'
import i18n from 'support/i18n'
import Navigation from 'support/navigation'
import View from './View'
import ErrorSubmitModal from './dialogs/ErrorSubmitContainer'
import SucessSubmitModal from './dialogs/SucessSubmitContainer'

import { getBenchUserDisplayProfile,getBenchDropdowndatalist , getBenchUserUpdateProfile} from 'services/bench'


const mapStateToProps = state => ({
  // hasUserProfileLoaded: state.data.userProfile.hasLoaded,
  // hasDomainLoaded: state.data.domain.dataLoaded,
  myEmail: state.data.user.userEmail,
  email: state.data.userProfile.email,
  fullName: state.data.userProfile.fullName,
  photoSrc: state.data.userProfile.photoSrc,
  balance: state.data.userProfile.balance,
  invested: state.data.userProfile.invested,
  portfolio: state.data.userProfile.portfolio,
  userBadges: state.data.userProfile.badges,
  allBadges: state.data.domain.badges,
  ideas: state.data.userProfile.ideas,
  // teamIdeas: state.data.userProfile.teamIdeas,
  // teamPilotIdeas: state.data.userProfile.teamPilotIdeas,
  // contributions: state.data.userProfile.contributions,
  // ideasInvestedIn: state.data.user.investments.ideas,
  // organisationsToBeShown: state.data.userProfile.organisations,
  // currencyIcon: state.data.domain.domainConfiguration.currencyIcon,
  // ideaStatuses: state.data.domain.domainConfiguration.ideaStatusStepper,
  // domainOrganisations: state.data.domain.organizations,
  // isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
  // organizationsName: state.data.domain.domainConfiguration.organizationsName,
  // timeline: state.data.userProfile.timeline,
  // isTimelineLoading: state.data.userProfile.isTimelineLoading,
  // isTimelineComplete: state.data.userProfile.isTimelineComplete,
  benchUserDisplayProfileResponse : state.data.bench.benchUserDisplayProfile,
  benchUserUpdateProfileResponse:state.data.bench.benchUserUpdateProfile,
  benchUserUpdateProfileLoading:state.data.bench.benchUserUpdateProfileLoading,
  benchUserDisplayProfileLoading:state.data.bench.benchUserDisplayProfileLoading,
  benchDropdownList: state.data.bench.benchDropdownList,
})

const mapDispatchToProps = dispatch => {
  return {
    getBenchUserDisplayProfileFn: (email) => dispatch(getBenchUserDisplayProfile(email)),
    getBenchUserUpdateProfileFn:(email,updateProfile)=>{
      console.log("update== index", updateProfile)
      const onSuccess = data => {
        const message='Your Profile is updated'
        // showPopupSuccessSnackbar(message, dispatch)
        showPopupDialog(<SucessSubmitModal description={message} />, dispatch)
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
            showPopupDialog(<ErrorSubmitModal description={messageTag} />, dispatch)
      }
      dispatch(getBenchUserUpdateProfile(email,updateProfile,onSuccess,onFailure))
      // dispatch(getBenchUserDisplayProfile(email))
    },
    getBenchDropdownDatalistFn: () => {
      dispatch(getBenchDropdowndatalist())
    },
    // getMyIdeasToInvestIn: () => dispatch(getMyIdeasToInvestIn()),
    // getUserContributions: email => dispatch(getUserContributions(email)),
    // onOrganisationClicked: (organisations, ids) => {
    //   const onFailure = () => {
    //     dispatch(getUserOrganisations())
    //     showPopupErrorSnackbar(i18n.t('PROFILE.ERROR_PUT_ORGANISATIONS'), dispatch)
    //   }
    //   dispatch(setUserOrganisations(organisations))
    //   dispatch(putUserOrganisations(ids, onFailure))
    // },
    // getUserIdeasPilotTeam: email => dispatch(getUserIdeasPilotTeam(email)),
    // getUserIdeasInvestedIn: email => dispatch(getInvestments(email)),
    // getOrganisations: () => dispatch(getActiveOrganizations()),
    // getUserOrganisations: () => dispatch(getUserOrganisations()),
    // getUserIdeasTeam: email => dispatch(getUserIdeasTeam(email)),
    // navigateToIdea: id => dispatch(Navigation.navigateToIdea(id)),
    // getUserIdeas: email => dispatch(getUserIdeas(email)),
    // getMyProfile: () => {
    //   const onError = () => {
    //     showPopupErrorSnackbar(i18n.t('PROFILE.ERROR_GETTING_PROFILE'), dispatch)
    //   }
    //   dispatch(getMyProfile(onError))
    // },
    // getUserProfile: email => {
    //   const onError = () => {
    //     showPopupErrorSnackbar(i18n.t('PROFILE.ERROR_GETTING_PROFILE'), dispatch)
    //   }
    //   dispatch(getUserProfile(email, onError))
    // },
    // getTimeline: email => dispatch(getUserTimeline(email)),
    // resetTimeline: () => dispatch(resetUserTimeline()),
    // onEditProfile: () => showPopupDialog(<EditMyProfileDialog />, dispatch),
    // onRequestInvestmentClick: investorEmail =>
    //   showPopupDialog(<RequestInvestment investorEmail={investorEmail} />, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

const DASHBOARD = 'dashboard'
const CHALLENGES_PAGE = 'challenges'
const NEW_CHALLENGE = 'new challenge'
const PROPOSE_CHALLENGE = 'propose challenge'
const EDIT_CHALLENGE = 'edit challenge'
const DETAIL_CHALLENGE = "challenge' detail"
const CUSTOM_LOGIN_PAGE = 'custom login page'
const EXPLORE_PAGE = 'explore'
const ALL_IDEAS = 'all ideas'
const HOW_IT_WORKS = 'how it works'
const IDENTITY_CHOOSE_PAGE = 'identity choose'
const INVESTMENTS_PAGE = 'investments'
const LEGAL_ACCEPTANCE_PAGE = 'legal acceptance'
const LOGIN_PAGE = 'login'
const LOGOUT_PAGE = 'logout'
const MY_IDEAS_PAGE = 'my ideas'
const MY_CHALLENGES_PAGE = 'my challenges'
const DRAFT_CHALLENGES = 'Draft Challenge'
const PENDING_VALIDATION_PAGE = 'pending validation'
const EVALUATION_BOARD_PAGE = 'evaluation board'
const EVALUATION_IDEA_DETAIL = 'evaluation idea detail'
const EVALUATION_EXPERT_COMMENTS = 'evaluation expert comments'
const PRIVACY_COOKIES_PAGE = 'privacy cookies'
const PRIVATE_IDEAS_PAGE = 'private ideas'
const PROFILE_PAGE = 'profile'
const PROFILE_PAGE_USER = 'profile'
const RANKING_PAGE = 'ranking'
const USER_PREFERENCES_PAGE = 'user preferences'
const NEW_IDEA = 'new idea'
const NEW_PRIVATE_IDEA = 'new private idea'
const NEW_IDEA_CHALLENGE_SELECTED = 'new idea with challenge'
const DETAIL_IDEA = 'detail idea'
const EDIT_IDEA = 'edit idea'
const RESET_PASSWORD = 'reset password'
const SIGN_UP = 'sign up'
const MAINTENANCE = 'maintenance'
const ROOT_LOGGED_PAGE = ''
const VALIDATE = 'validate'
const AZURE_LOGIN = 'azure login'
const ADFS_LOGIN = 'adfs login'
const SSO_AUTH_ERROR = 'sso auth error'
const LANDING_PAGE = 'landing page'
const ABOUT_US = 'about us'
const NOVA_RESEARCH = 'nova research'
const MEDIA_FILE = 'media file'
const CONTACTUS = 'contact us'
const CASESTUDY = 'Case study'
const DETAIL_CASESTUDY = 'detail Case study'
const INVESTMENT_DETAILS_PAGE = 'investment details page'
const BALANCE_DETAILS_PAGE = 'balance details page'
const SEARCH_PAGE = 'search page'
const MY_INVESTED_IDEAS = 'My Invested Ideas'
const THEBENCH = 'the bench'
const BENCHUSERDETAILS ="the bench user details"
const BENCHPROFILE = 'Bench Profile'

module.exports = {
  BENCHPROFILE,
  THEBENCH,
  BENCHUSERDETAILS,
  DASHBOARD,
  CONTACTUS,
  CASESTUDY,
  DETAIL_CASESTUDY,
  CHALLENGES_PAGE,
  DRAFT_CHALLENGES,
  NEW_CHALLENGE,
  PROPOSE_CHALLENGE,
  EDIT_CHALLENGE,
  DETAIL_CHALLENGE,
  CUSTOM_LOGIN_PAGE,
  EXPLORE_PAGE,
  HOW_IT_WORKS,
  IDENTITY_CHOOSE_PAGE,
  INVESTMENTS_PAGE,
  LEGAL_ACCEPTANCE_PAGE,
  LOGIN_PAGE,
  LOGOUT_PAGE,
  MY_IDEAS_PAGE,
  MY_CHALLENGES_PAGE,
  PENDING_VALIDATION_PAGE,
  EVALUATION_BOARD_PAGE,
  EVALUATION_IDEA_DETAIL,
  EVALUATION_EXPERT_COMMENTS,
  PRIVACY_COOKIES_PAGE,
  PRIVATE_IDEAS_PAGE,
  PROFILE_PAGE,
  PROFILE_PAGE_USER,
  RANKING_PAGE,
  USER_PREFERENCES_PAGE,
  NEW_IDEA,
  NEW_PRIVATE_IDEA,
  NEW_IDEA_CHALLENGE_SELECTED,
  DETAIL_IDEA,
  EDIT_IDEA,
  RESET_PASSWORD,
  SIGN_UP,
  ROOT_LOGGED_PAGE,
  VALIDATE,
  MAINTENANCE,
  AZURE_LOGIN,
  ADFS_LOGIN,
  SSO_AUTH_ERROR,
  LANDING_PAGE,
  ABOUT_US,
  NOVA_RESEARCH,
  MEDIA_FILE,
  ALL_IDEAS,
  INVESTMENT_DETAILS_PAGE,
  BALANCE_DETAILS_PAGE,
  SEARCH_PAGE,
  MY_INVESTED_IDEAS
}

import React from 'react'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import ModalAlertController from './support/popup_dialogs/modal_alert_controller'
import { initialLoadActionConditional } from './actions/async/InitialLoadActions'
import DataInitializer from './support/data_initializer'
import WelcomeSplash from './support/splash'
import RootNav from './support/navigation/navigators/root_nav'
import ThemeProvider from './support/theme/theme_provider'
import HelpWidget from './support/help_widget'
import { persistor, store } from './store'
import Loader from './support/loader/component'
import MainLoader from './support/loader/nxt_loader'
import NovaNotSupport from './img/nova_not_support.jpg'
import { isIE } from 'react-device-detect'
import './App.css'
import ReactGATracker from './components/google_analytics/ReactGATracker'
import { ThemeProvider as ThemeProviderVF } from 'styled-components'
// import themeWS10 from '@vfuk/core-theme-ws10'
import { Error } from './pagesNewUi'

const currentIndexLocationHost = window.location.origin === process.env.REACT_APP_BACKEND_BASE_URL

const App = (props) => {
  if (isIE)
    return (
      <div>
        <img src={NovaNotSupport} width="100%" height="100%" alt="NOVA" />
      </div>
    )


  //  test 
    
  return (
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Loader loader={<MainLoader />}>
          { currentIndexLocationHost ?
            <BrowserRouter>
            {/* <ThemeProviderVF theme={themeWS10}> */}
              <ThemeProvider>

                <DataInitializer onInit={initialLoadActionConditional}>
                  <ModalAlertController>
                    {/* <HelpWidget> */}
                      <WelcomeSplash>
                        <RootNav />
                        <ReactGATracker />
                      </WelcomeSplash>
                    {/* </HelpWidget> */}
                  </ModalAlertController>
                </DataInitializer>
 
              </ThemeProvider>
              {/* </ThemeProviderVF> */}
            </BrowserRouter>
             : <Error /> } 
          </Loader>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  )
}

export default App

import { connect } from 'react-redux'
import { getRankingService } from 'services/ranking'
import Navigation from 'support/navigation'
import View from './View'
import { getBenchDropdowndatalist , getSearchApiBenchDatalist,getAllBenchUserDataList} from 'services/bench'


const mapStateToProps = state => {
  return {
    myEmail: state.data.user.userEmail,
    benchDropdownList: state.data.bench.benchDropdownList,
    benchSearchUserList: state.data.bench.benchSearchUserList,
    benchUser: state.data.bench.benchUser,
    BenchUserLoading: state.data.bench.BenchUserLoading,
    BenchDropdownListLoading: state.data.bench.BenchDropdownListLoading,
    BenchSearchListLoading: state.data.bench.BenchSearchListLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getBenchDropdownDatalistFn: () => {
      dispatch(getBenchDropdowndatalist())
    },
    getSearchApiBenchDatalistFn:(usernameSearch)=>{
        dispatch(getSearchApiBenchDatalist(usernameSearch))
    },
    getAllBenchUserDataList:(filterData)=>{
      const {orderDataValue,
        role,
        skill,
        communities } = filterData
        // console.log("🚀 ~ orderDataValue:", orderDataValue,role,communities)
        // console.log("🚀 ~ orderDataValue:",  Object.values(communities)[0]) 
      const filterDataWithOrderArray = Array.isArray(orderDataValue) ? orderDataValue.map((option) => option?.value) : orderDataValue?.value;
      const filterDataWithRolesArray = Array.isArray(role) ? role.map((option) => option?.value) : [];
      const filterDataWithSkillArray = Array.isArray(skill) ? skill.map((option) => option?.value) : [];
      const filterDataWithCommunitiesArray = Array.isArray(communities) ? communities.map((option) => option?.value) : communities?.value;
        dispatch(getAllBenchUserDataList({filterDataWithOrderArray,
        filterDataWithRolesArray,
        filterDataWithSkillArray,
        filterDataWithCommunitiesArray }))
    }
     
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

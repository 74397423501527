
export const GET_BENCH_DATA_LIST_STARTED = 'GET_BENCH_DATA_LIST_STARTED'
export const GET_BENCH_DATA_LIST_FAILURE = 'GET_BENCH_DATA_LIST_FAILURE'
export const GET_BENCH_DATA_LIST_SUCCESS = 'GET_BENCH_DATA_LIST_SUCCESS'

export const GET_BENCH_SEARCH_DATA_LIST_STARTED = 'GET_BENCH_SEARCH_DATA_LIST_STARTED'
export const GET_BENCH_SEARCH_DATA_LIST_SUCCESS = 'GET_BENCH_SEARCH_DATA_LIST_SUCCESS'
export const GET_BENCH_SEARCH_DATA_LIST_FAILURE = 'GET_BENCH_SEARCH_DATA_LIST_FAILURE'

export const GET_All_USER_BENCH_DATA_LIST_STARTED = 'GET_All_USER_BENCH_DATA_LIST_STARTED'
export const GET_All_USER_BENCH_DATA_LIST_SUCCESS = 'GET_All_USER_BENCH_DATA_LIST_SUCCESS'
export const GET_All_USER_BENCH_DATA_LIST_FAILURE = 'GET_All_USER_BENCH_DATA_LIST_FAILURE'

export const GET_BENCH_USER_DISPLAY_PROFILE_STARTED = 'GET_BENCH_USER_DISPLAY_PROFILE_STARTED'
export const GET_BENCH_USER_DISPLAY_PROFILE_SUCCESS = 'GET_BENCH_USER_DISPLAY_PROFILE_SUCCESS'
export const GET_BENCH_USER_DISPLAY_PROFILE_FAILURE = 'GET_BENCH_USER_DISPLAY_PROFILE_FAILURE'

export const GET_BENCH_USER_UPDATE_PROFILE_STARTED = 'GET_BENCH_USER_UPDATE_PROFILE_STARTED'
export const GET_BENCH_USER_UPDATE_PROFILE_SUCCESS = 'GET_BENCH_USER_UPDATE_PROFILE_SUCCESS'
export const GET_BENCH_USER_UPDATE_PROFILE_FAILURE = 'GET_BENCH_USER_UPDATE_PROFILE_FAILURE'


import NavigationService from './NavigationService'
import * as pages from '../../common_constants/Pages'

function navigateToAdmin(crsf) {
  window.location.href = `${process.env.REACT_APP_ADMIN_BASE_URL}?crsf=${crsf}`
  return null
}

function navigateToExternal(url) {
  window.location.href = url
  return null
}

function navigateToDomain(domainNS) {
  window.location.replace(`${process.env.REACT_APP_PUBLIC_BASE_URL}/${domainNS}/explore`)
  return null
}

function navigateBack() {
  NavigationService.getHistory().goBack()
}

function navigateToChallenges() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.CHALLENGES_PAGE].path)
}

function navigateToChallengeDraft(){
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.DRAFT_CHALLENGES].path)
}

function navigateToChallengeDetail(id) {
  const ROUTES = NavigationService.getRoutes()
  const toChallenge = ROUTES[pages.DETAIL_CHALLENGE].path.replace(':id', id)
  NavigationService.getHistory().push(toChallenge)
}

function navigateToChallengeForm(id) {
  const ROUTES = NavigationService.getRoutes()
  const toChallenge = ROUTES[pages.EDIT_CHALLENGE].path.replace(':id', id)
  NavigationService.getHistory().push(toChallenge)
}

function navigateToChallengeNew() {
  const ROUTES = NavigationService.getRoutes()
  const toChallenge = ROUTES[pages.NEW_CHALLENGE].path
  NavigationService.getHistory().push(toChallenge)
}

function navigateToMyChallenge() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.MY_CHALLENGES_PAGE].path)
}

function navigateToProposeChallenge() {
  const ROUTES = NavigationService.getRoutes()
  const toProposeChallenge = ROUTES[pages.PROPOSE_CHALLENGE].path
  NavigationService.getHistory().push(toProposeChallenge)
}

function navigateToDashboard() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.DASHBOARD].path)
}

function navigateToExplore() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.EXPLORE_PAGE].path)
}

function navigateToBench() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.THEBENCH].path)
}

function navigateToAllIdeas() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.ALL_IDEAS].path)
}

function navigateToHowItWorks() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.HOW_IT_WORKS].path)
}

function navigateToNewIdea(challengeId = '') {
  const ROUTES = NavigationService.getRoutes()
  const toIdea = challengeId
    ? ROUTES[pages.NEW_IDEA_CHALLENGE_SELECTED].path.replace(':challengeId', challengeId)
    : ROUTES[pages.NEW_IDEA].path
  NavigationService.getHistory().push(toIdea)
}

function navigateToNewPrivateIdea() {
  const ROUTES = NavigationService.getRoutes()
  const toIdea = ROUTES[pages.NEW_PRIVATE_IDEA].path
  NavigationService.getHistory().push(toIdea)
}

function navigateToEditIdea(id) {
  const ROUTES = NavigationService.getRoutes()
  const toIdea = ROUTES[pages.EDIT_IDEA].path.replace(':id', id)
  NavigationService.getHistory().push(toIdea)
}

function navigateToEditIdeaNew(id) {
  const ROUTES = NavigationService.getRoutes()
  const toIdea = ROUTES[pages.EDIT_IDEA].path.replace(':id', id)
  NavigationService.getHistory().push(toIdea)
}


function navigateToIdea(id) {
  const ROUTES = NavigationService.getRoutes()
  const toIdea = ROUTES[pages.DETAIL_IDEA].path.replace(':id', id)
  NavigationService.getHistory().push(toIdea)
}

function navigateToIdentityChoose() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.IDENTITY_CHOOSE_PAGE].path)
}

function navigateToMyIdeas() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.MY_IDEAS_PAGE].path)
}

function navigateToInvestments() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.INVESTMENTS_PAGE].path)
}

function navigateToInvestmentDetails() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.INVESTMENT_DETAILS_PAGE].path)
}

// function navigateToBalanceDetails() {
//   const ROUTES = NavigationService.getRoutes()
//   NavigationService.getHistory().push(ROUTES[pages.BALANCE_DETAILS_PAGE].path)
// }

function navigateToLogout() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.LOGOUT_PAGE].path)
}

function navigateToLogin() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.LOGIN_PAGE].path)
}

function navigateToPendingValidation() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.PENDING_VALIDATION_PAGE].path)
}

function navigateToEvaluationBoard() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.EVALUATION_BOARD_PAGE].path)
}

function navigateToEvaluationIdeaDetails(title) {
  const ROUTES = NavigationService.getRoutes()
  const toEvaluationIdea = ROUTES[pages.EVALUATION_IDEA_DETAIL].path.replace(':title', title)
  NavigationService.getHistory().push(toEvaluationIdea)
}

function navigateToPrivateIdeas() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.PRIVATE_IDEAS_PAGE].path)
}

function navigateToProfile() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.PROFILE_PAGE].path)
}

function navigateToRanking() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.RANKING_PAGE].path)
}

function navigateToSettings() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.USER_PREFERENCES_PAGE].path)
}

function navigateToUserProfile(email) {
  const ROUTES = NavigationService.getRoutes()
  const toProfile = ROUTES[pages.PROFILE_PAGE_USER].path.replace(':email', email)
  NavigationService.getHistory().push(toProfile)
}

// function navigateToSearchPage(email) {
//   const ROUTES = NavigationService.getRoutes()
//   const toSearch = ROUTES[pages.SEARCH_PAGE].path
//   NavigationService.getHistory().push(toSearch)
// }

function navigateToSignUp(domain) {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.SIGN_UP].path.replace(':domain', domain))
}

function navigateToCustomLogin(domain) {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(
    ROUTES[pages.CUSTOM_LOGIN_PAGE].path.replace(':domain', domain),
  )
}

// STATIC EXTERNAL URLS

function navigateToStaticHelpNextinit() {
  navigateToExternal('https://help.nextinit.com/')
}

function navigateToSSOError() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.SSO_AUTH_ERROR].path)
}

function navigateToAboutus() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.ABOUT_US].path)
}
function navigateToNovaResearch() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.NOVA_RESEARCH].path)
}

function navigateToNovaContact() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.CONTACTUS].path)
}


function navigateToMyInvestedIdeas() {
  const ROUTES = NavigationService.getRoutes()
  NavigationService.getHistory().push(ROUTES[pages.MY_INVESTED_IDEAS].path)
}
function navigateToCaseStudy() {
  const ROUTES = NavigationService.getRoutes()
  const toCaseStudy = ROUTES[pages.CASESTUDY].path
  NavigationService.getHistory().push(toCaseStudy)
}

function navigateToCaseStudyDetails(title) {
  const ROUTES = NavigationService.getRoutes()
  const toCaseStudyDetail = ROUTES[pages.DETAIL_CASESTUDY].path.replace(':title', title)
  NavigationService.getHistory().push(toCaseStudyDetail)
}


export default {
  navigateBack,
  navigateToAdmin,
  navigateToChallengeDetail,
  navigateToChallengeForm,
  navigateToChallengeNew,
  navigateToProposeChallenge,
  navigateToChallenges,
  navigateToDashboard,
  navigateToDomain,
  navigateToExplore,
  navigateToExternal,
  navigateToHowItWorks,
  navigateToIdea,
  navigateToNewIdea,
  navigateToNewPrivateIdea,
  navigateToEditIdea,
  navigateToEditIdeaNew,
  navigateToIdentityChoose,
  navigateToMyIdeas,
  navigateToMyChallenge,
  navigateToChallengeDraft,
  navigateToInvestments,
  navigateToInvestmentDetails,
  navigateToLogout,
  navigateToPendingValidation,
  navigateToEvaluationBoard,
  navigateToEvaluationIdeaDetails,
  navigateToPrivateIdeas,
  navigateToProfile,
  navigateToRanking,
  navigateToSettings,
  navigateToSignUp,
  navigateToStaticHelpNextinit,
  navigateToUserProfile,
  navigateToLogin,
  navigateToCustomLogin,
  navigateToSSOError,
  navigateToAboutus,
  navigateToNovaResearch,
  navigateToNovaContact,
  navigateToAllIdeas,
  navigateToMyInvestedIdeas,
  navigateToCaseStudyDetails,
  navigateToCaseStudy,
  navigateToBench
}

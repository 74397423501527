
const styles = theme =>({
    container:{
      height: "auto",
      // paddingLeft: "6%",
      // paddingRight: "6%",
      paddingBottom: "60px"

    //   '& .checkboxStyle':{
    //     border:'0',
    //     padding:'5px'
    //   },

    //   '& .custom-checkbox' :{
    //     display: inline-block,
    //     position: relative,
    //     cursor: pointer,
    //     fontSize: '22px',
    //     userSelect: none,
    //   },
      
    //   '& .custom-checkbox input ': {
    //     position: absolute,
    //     opacity: 0,
    //     cursor: pointer,
    //   },
      
    //  '& .custom-checkbox .checkmark ':{
    //     position: absolute,
    //     top: 0,
    //     left: 0,
    //     height: '25px',
    //     width: '25px',
    //     backgroundColor: '#ccc',
    //     borderRadius: '4px',
    //   },
      
    //  '& .custom-checkbox input:checked ~ .checkmark' :{
    //     backgroundColor: '#2196F3',
    //   },
      
    //  '& .custom-checkbox .checkmark:after' :{
    //     content: "",
    //     position: absolute,
    //     display: none,
    //   },
      
    // '& .custom-checkbox input:checked ~ .checkmark:after ':{
    //     display: block,
    //   },
      
    //  '& .custom-checkbox .checkmark:after' :{
    //     left: '9px',
    //     top: '5px',
    //     width: '5px',
    //     height: '10px',
    //     border: 'solid white',
    //     borderWidth: '0 3px 3px 0',
    //     transform:' rotate(45deg)',
    //   } 


    }




    
  })

export default styles

import { connect } from 'react-redux'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import Navigation from 'support/navigation'
import i18n from 'support/i18n'

const mapStateToProps = () => ({
  title: 'Thank you!',
  description: 'Your Profile is Updated!',
  mainButtonText: i18n.t('ACCEPT'),
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    mainButtonAction: () => {
      dispatch(hideDialog())
      dispatch(Navigation.navigateToBench)
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)

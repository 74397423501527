import { connect } from 'react-redux'
import { getRankingService } from 'services/ranking'
import Navigation from 'support/navigation'
import View from './View'
import { getBenchUserDisplayProfile ,getBenchDropdowndatalist , getSearchApiBenchDatalist,getAllBenchUserDataList} from 'services/bench'


const mapStateToProps = state => {
  return {
    // myEmail: state.data.user.userEmail,
    // benchDropdownList: state.data.bench.benchDropdownList,
    // benchSearchUserList: state.data.bench.benchSearchUserList,
    // benchUser: state.data.bench.benchUser,
    // BenchUserLoading: state.data.bench.BenchUserLoading,
    // BenchDropdownListLoading: state.data.bench.BenchDropdownListLoading,
    // BenchSearchListLoading: state.data.bench.BenchSearchListLoading,
    benchUserDisplayProfileResponse : state.data.bench.benchUserDisplayProfile,
    benchUserDisplayProfileLoading:state.data.bench.benchUserDisplayProfileLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getBenchUserDisplayProfileFn: (email) => dispatch(getBenchUserDisplayProfile(email)),
   
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

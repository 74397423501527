import React from 'react'
import {Link} from 'react-router-dom'

import * as pages from '../../common_constants/Pages'
import NavigationService from '../../support/navigation/NavigationService'
import investorsImg from '../../img/imgs/investors.svg'
// import investorsImg from '../imgs/investors.svg';

const validateValue = (value) => value !== undefined && value !== null && value !== '';

export const BenchUserCard = ({ user, classes, }) => {


  const ROUTES = NavigationService.getRoutes()
  const detailbenchPath = ROUTES[pages.BENCHUSERDETAILS].path.replace(":email",user?.id);

  return (
    <div className={classes.benchCard}>
      <div className={classes.benchCard_userimg}>
        <img src={user.photo ? user.photo : investorsImg} alt='user avatar' />
      </div>
      <div className={classes.benchCard_content}>
        <div className="card">
          <h2>{validateValue(user.name) ? user.name : null}</h2>
          <p>Email: {validateValue(user.id) ? user.id : null}</p>
          <p>skills: {validateValue(user.skills) ? user.skills : null}</p>
          <p>roles: {validateValue(user.roles) ? user.roles : null}</p>
          <p>Balance: {validateValue(user.balance) ? user.balance : null}</p>
          <p>division: {validateValue(user.division) ? user.division : null}</p>
        </div>
        <Link to={{
          pathname: `${detailbenchPath}`,
          state: user
        }}  className={classes.viewlink} >View More</Link>
      </div>
    </div>
  )
}



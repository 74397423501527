import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import investorsImg from '../../img/imgs/investors.svg'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const BenchDetails = ({ classes, benchUserDisplayProfileResponse, getBenchUserDisplayProfileFn }) => {
    const { email = 'abc@abc.com' } = useParams()

    useEffect(() => {
        if (email) getBenchUserDisplayProfileFn(email)
    }, [])

    const location = useLocation()
    // const { name, photo, alias, badges } = location?.state || {}
    // // console.log("🚀 ~ BenchDetails ~ location:", location)

    return (
        <div className={classes.container}>
            <BenchUserProfile classes={classes} benchUserDisplayProfileResponse={benchUserDisplayProfileResponse} />
            <MyAvailabilityToJoinIdeaTeam classes={classes} benchUserDisplayProfileResponse={benchUserDisplayProfileResponse} />
            <UserStats classes={classes} benchUserDisplayProfileResponse={benchUserDisplayProfileResponse} />
            {benchUserDisplayProfileResponse?.badges?.lenght > 0 ? <BadgesComponent classes={classes} benchUserDisplayProfileResponse={benchUserDisplayProfileResponse} /> : null}
        </div>
    )
}

const BenchUserProfile = (props) => {
    const { benchUserDisplayProfileResponse, classes } = props
    const { photo, name, alias, id, communities, currentProvider, countryId } = benchUserDisplayProfileResponse || {}
    return (
        <div className={classes.userAvatar}>
            <div className='imgProfile'>
                <img src={photo !== null ? photo : investorsImg} alt='avatar' />
            </div>
            <div className='userContent'>
                <p><span> Name:</span> {name} </p>
                <p> <span>Email:</span> {alias} </p>
                <p> <span>Communities:</span> {communities} </p>
                <p> <span>country:</span> {countryId} </p>
                {/* <p> <span>Provider:</span> {currentProvider} </p> */}

            </div>
        </div>
    )
}

const MyAvailabilityToJoinIdeaTeam = (props) => {
    const { benchUserDisplayProfileResponse, classes } = props
    const { roles, skills, shortInfo, availabilityInTeam, jobTitle, division,market,company } = benchUserDisplayProfileResponse || {}
    // console.log("🚀 ~ MyAvailabilityToJoinIdeaTeam ~ availabilityInTeam:", availabilityInTeam)
    return (
        <div className={classes.joinIdeaTeamContent}>
             <div className='block'>
                <h3>job Title:</h3>
                <p> {jobTitle}  </p>
            </div>
            <div className='block'>
                <h3>Company:</h3>
                <p> {company}  </p>
            </div>
            <div className='block'>
                <h3>Market:</h3>
                <p> {market}  </p>
            </div>
            <div className='block'>
                <h3>Division:</h3>
                <p> {division}  </p>
            </div>


            <h2>My Availability to Join an Idea Team</h2>
           
            <div className='block'>
                <h3>Available to be recruited:</h3>
                <p> {availabilityInTeam ? 'yes' : 'No'} </p>
            </div>
            <div className='block'>
                <h3>Ideal team role to fill:</h3>
                <p> {roles} </p>
            </div>
            <div className='block'>
                <h3>Skills in order of strength:</h3>
                <p> {skills} </p>
            </div>

            <div className='block'>
                <h3>Bio block:</h3>
                <p>{shortInfo}</p>
            </div>

        </div>
    )
}

const BadgesComponent = (props) => {
    const { benchUserDisplayProfileResponse, classes } = props
    const { badges = [], name } = benchUserDisplayProfileResponse || {}
    return (
        <div className={classes.badgesItem}>
            <h2>Badges for {name}</h2>

            <ItemList items={badges} />

        </div>
    )
}

const UserStats = (props) => {
    const { benchUserDisplayProfileResponse, classes } = props
    const { ideas, numChallenges, name, currentAmountInvested, amountInvested, balance } = benchUserDisplayProfileResponse || {}
    return (
        <div className={classes.userStats}>
            <h2>Stats for {name}</h2>
            <div className='alignStats'>
                <ReuseStatsComponent value={ideas} valueTitle='Ideas' />
                <ReuseStatsComponent value={numChallenges} valueTitle='Number of Challenges' />
                <ReuseStatsComponent value={amountInvested} valueTitle='Amount Invested' />
                <ReuseStatsComponent value={balance} valueTitle='Balance' />
                <ReuseStatsComponent value={currentAmountInvested} valueTitle='current Amount Invested' />
            </div>
        </div>
    )
}


const ReuseStatsComponent = ({ value, valueTitle } = {}) => {
    return (
        <div className='userStatsBlock'>
            <p>{value}</p>
            <h3>{valueTitle}</h3>
        </div>
    )
}

const ItemList = ({ items } = {}) => {
    return (
        <div className='alignbadges'>
            {items?.map((item, index) => (
                <div className='badgesItem' key={item.name}>

                    <img src={item.imgSrc ? item.imgSrc : investorsImg} alt={item.name} title={item.name} />
                    <h3>{item.name}</h3>
                </div>
            ))}
        </div>
    );
};


export default withStyles(styles)(BenchDetails)

/*eslint-disable   jsx-a11y/label-has-associated-control*/
import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { Title } from 'components_newui/Title/Title'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import MultiSelectWithCheckbox from 'components_newui/multiSelect/View'


const BenchProfile = (props) => {
  const  {classes ,benchUserDisplayProfileLoading,benchUserUpdateProfileLoading,benchUserUpdateProfileResponse,getBenchUserUpdateProfileFn ,getBenchUserDisplayProfileFn, myEmail,benchUserDisplayProfileResponse,benchDropdownList,getBenchDropdownDatalistFn}= props || {} 

   React.useEffect(() => {
    getBenchDropdownDatalistFn()
  }, [getBenchDropdownDatalistFn])

  console.log("benchUserDisplayProfileResponse ----> update after,  before update", benchUserDisplayProfileResponse )
   
  return (
    <div className={classes.BenchProfileWrp} >
        <BenchUpdateProfile  
            classes={classes}  
            benchUserUpdateProfileLoading={benchUserUpdateProfileLoading}
            benchUserDisplayProfileLoading={benchUserDisplayProfileLoading}
            benchUserUpdateProfileResponse={benchUserUpdateProfileResponse}
            getBenchUserUpdateProfileFn={getBenchUserUpdateProfileFn}
            benchDropdownList={benchDropdownList}
            benchUserDisplayProfileResponse={benchUserDisplayProfileResponse}
            getBenchUserDisplayProfileFn={getBenchUserDisplayProfileFn} 
            myEmail={myEmail} 
        />
    </div>
  )
}


const BenchUpdateProfile = ({classes, benchUserUpdateProfileResponse,benchUserDisplayProfileLoading,benchUserUpdateProfileLoading,getBenchUserUpdateProfileFn, benchDropdownList,benchUserDisplayProfileResponse,getBenchUserDisplayProfileFn, myEmail} = {}) =>{
    
    const { register, handleSubmit,setValue } = useForm();
    const [data, setData] = useState("");
    const {name='',alias='',company='',division='',jobTitle='',market='',availabilityInTeam=false,skills='ALL',roles='ALL',shortInfo=''} = benchUserDisplayProfileResponse || {}

    
    const setDefaultValues = () => {
        setValue("Name", name);
        setValue("Email", alias);
        setValue("company", company);
        setValue("yourMarket", market);
        setValue("yourDivision", division);
        setValue("yourjobTitle",jobTitle);
        setValue("jointeam", availabilityInTeam);
        setValue("roles", roles);
        setValue("skills", skills);
        setValue("textbox", shortInfo);
      };

      const handleSubmitForm = (datavalue) => {
        // e.preventDefault()
        // const  jsonToObj = JSON.stringify(datavalue)
        //  setData(jsonToObj)
        return  getBenchUserUpdateProfileFn(myEmail,datavalue)
    }

    useEffect( ()=>{
        if(myEmail !== ''){
            getBenchUserDisplayProfileFn(myEmail)
            // setDefaultValues()
        }
    },[myEmail])

    useEffect(()=>{
            setDefaultValues()
    },[name,alias,company,division,jobTitle,market,availabilityInTeam,skills,roles])
 


    const rolesArray = benchDropdownList.map(item => item.roles);
    const validRolesArray = [...new Set(rolesArray.filter(value => value !== undefined && value !== null && value !== ''))] 
    ?.map((option)=>( { value:option, label: option })) ;
    const SkillsArray = benchDropdownList.map(item => item.skills);
    const validSkillsArray = [...new Set(SkillsArray.filter(value => value !== undefined && value !== null && value !== ''))]
    ?.map((option)=>( { value:option, label: option }));
    
    // const optionsRoles = [
    //     // { value: 'ALL', label: 'ALL',isDisabled:true },
    //     ...validRolesArray
    // ]
    // .map(option => (typeof option === 'string' ?  console.log("options==========obj",  { value: option, label: option })  :  console.log("options=========plain=", options)));
    
  
    const initialRoles  = roles?.length > 0 ?  roles.split(',').map(role => ({ value: role, label: role }))  : [] ;
    const initialSkills  = skills?.length > 0 ?  skills.split(',').map(skill => ({ value: skill, label: skill }))  : [] ;
    // const initialRoles =  roles?.lenght > 1 ? [ ...roles.split(',').map(role => ({ value: role, label: role }))] : null

    

    return (
       <NxtCircularLoader isLoading={benchUserDisplayProfileLoading || benchUserUpdateProfileLoading}>  
        <form className='benchProfileFromWrp' onSubmit={handleSubmit(handleSubmitForm)}>
            <Title title='Bench User Profile' />
            <div className='fieldbox fieldDisable'>
                <label htmlFor='Name'>Name</label>
                <input id='Name'  type="text"  name="Name" placeholder="Name" {...register("Name")}  disabled/>
            </div>
            <div className='fieldbox fieldDisable'>
                <label htmlFor="Email">Email</label>
                <input type="email" id="Email" name="Email" placeholder="abc@abc.com" {...register("Email")}  disabled />
            </div>
            <div className='fieldbox'>
                <label htmlFor="company">Company</label>
                <input type="text" id="company" name="company" placeholder="company" {...register("company")} />
            </div>
            <div className='fieldbox'>
                <label htmlFor="yourMarket">Your Market</label>
                <input type="text" id="yourMarket" name="yourMarket" placeholder="Your Market" {...register("yourMarket")} />
            </div>
            <div className='fieldbox'>
                <label htmlFor="yourDivision">Your Division</label>
                <input type="text" id="yourDivision" name="yourDivision" placeholder="your Division" {...register("yourDivision")} />
            </div>
            <div className='fieldbox'>
                <label htmlFor="yourjobTitle">Job Title</label>
                <input type="text" id="yourjobTitle" name="yourjobTitle" placeholder="Soft engg." {...register("yourjobTitle")} />
            </div>


            <div className='subheading'> 
            <h2>My Availability to Join an Idea Team</h2>
            </div>

          
            <div className='fieldbox checkboxInline'>
                <input type="checkbox" id="jointeam" name="jointeam"  {...register("jointeam")} />
                <label htmlFor="jointeam">Are you available to join a team?</label>
            </div>

            <div className='fieldbox'>
                <label htmlFor='roles'>Roles</label>
                <MultiSelectWithCheckbox  
                    options={validRolesArray}  
                    register={register} 
                    name="roles" 
                    setValue={setValue} 
                    initialValue={ initialRoles } 
                />
                {/* <select {...register("roles")}>
                    <option value="Frontend Developer">Frontend Developer</option>
                    <option value="Backend">Backend Developer</option>
                    <option value="other">UI Design</option>
                </select> */}
            </div>

            <div className='fieldbox'>
                <label htmlFor='skills'>Skills</label>
                <MultiSelectWithCheckbox  
                    options={validSkillsArray}  
                    register={register} 
                    name="skills" 
                    setValue={setValue} 
                    initialValue={ initialSkills } 
                />

                {/* <select {...register("skills")}>
                    <option value="Frontend Developer">Frontend Developer</option>
                    <option value="Backend">Backend Developer</option>
                    <option value="other">UI Design</option>
                </select> */}
            </div>

            <div className='fieldbox'>
                <label htmlFor='desc'>Provide a short description of your experience, skills and accomplishments for others to see when viewing your profile:</label>
                <textarea id='desc' {...register("textbox")} />
            </div>
            

            <button type="submit">Update profile</button>
        </form>
        </NxtCircularLoader>   
    )
}


export default withStyles(styles)(BenchProfile)

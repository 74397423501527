
const styles = () => ({
    container: {
      maxWidth: '1200px',
      margin: '2rem auto',

      // margin: '30px auto',
      // display: 'flex',
      // flexWrap: 'wrap',
      // justifyContent: 'space-around',
      // width: '60%',
      // 'grid-column-start': '1',
      // 'grid-column-end': '4',
      '& .wrp2col':{
        display: 'flex',
        justifyContent: 'space-between',
        '& .fliter5':{
          '& input[type="text"]':{
            width:'300px'
          }
        }
      }
    },
    alignHeading:{
      marginBottom:'2rem',
      marginTop:'2rem',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      '& .viewlink':{
        maxWidth:'190px',
      },
    },
    
    bench_filter:{
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: '2rem',
      gap:'1rem',
      '& label':{
        color: '#333',
        fontSize: '1.125rem',
        fontFamily: 'VodafoneRg-bold',
        marginBottom:'1rem',
        display:'block'
      },
      '& .dropDownLableBlock':{
        display:'block',
        marginRight:'1.125rem',
        marginLeft:'0',
        '& .selectbox':{
          width:'11rem'
        }
      } 
    },
    benchCard:{ 
      maxWidth:'100%',
      width:'100%',
      border:'1.5px solid #ccc',
      borderRadius:'10px',
      margin:'8px 0px',
      padding:'1rem',
      'box-sizing':'border-box',
   
    },
    benchCard_userimg:{
      height:'210px',
      overflow:'hidden',
      width: '200px',
      margin: '1rem auto',
      '& img':{
        width:'100%'
      }
    },
    wrp_bench:{
      display: 'grid',
      gap: '1rem',
      '@media (min-width:600px)':{
        'grid-template-columns': 'repeat(2, 1fr)' 
      },
      '@media  (min-width:900px)':{
        'grid-template-columns': 'repeat(3, 1fr)' 
      }
    },

    search_text_div: {
      // overflow:'hidden',
      width: "100%",
      // height: "48px",
      // border: "2px solid #6551FF",
      borderRadius: "24px",
      // display: "flex",
      // justifyContent: "space-between",
      // alignItems: "center",
      // backgroundColor: "#FFFFFF",
      // position: 'relative',
      // marginBottom:'10px',
      
      '@media(min-width:900px)':{
        // width: "250px",
      },
      '@media(max-width:480px)':{
        height: "38px",
        border: "1px solid #6551FF",
      },
      '& label':{
        color: '#333',
        fontSize: '1.125rem',
        fontFamily: 'VodafoneRg-bold',
        marginBottom:'1rem',
        display:'block'
      },
      '& input[type="text"]':{
        width: '100%',
        maxWidth:'100%',
        height: '48px',
        padding: '10px',
        fontSize: "18px",
        fontFamily: "VodafoneRg-Regular",
        color: "#000",
        backgroundColor: "#FFFFFF",
        position: 'relative',
        marginBottom:'10px',
        border: "2px solid #6551FF",
        borderRadius:'24px',
        boxSizing:'border-box',
        '&:focus': {
          border: "0",
          outline: "none",
        },
      },
    },
    
    
    '& .titles':{
      color: '#333333',
      margin: '0px 0px 16px 0px',
      fontSize: '2rem',
      fontFamily:' VodafoneRg-Regular',
      fontWeight: '400',
      lineHeight: 1
    },

    viewlink:{
      backgroundColor:'#e60000',
      border:'1px solid #e60000',
      color:'#fff',
      // width: '100%',
      display: 'block',
      padding: '12px',
      'box-sizing': 'border-box',
      textAlign: 'center',
      fontSize: '20px',
      fontFamily: "VodafoneRg-Regular",
      borderRadius:'5px',
      transition:'all 300ms ease',
      '&:hover':{
        background:'transparent',
        border:'1px solid #e60000',
        color:'#333'
      }
    },

    userAvatar:{
      marginTop:'2rem',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      borderBottom:"2px dashed #333",
      paddingBottom:'3rem',
      '& .userContent':{
        fontSize: "18px",
        fontFamily: "VodafoneRg-Bold",
        '& span':{
          fontFamily: "VodafoneRg-Regular",
        },
       
      },
      '& .imgProfile':{
        width: '160px',
        height: '160px',
        // border: '1px solid #ccc',
        boxShadow:'0px 1px 2px #ccc',
        borderRadius: '10px',
        marginRight:'1rem',
        padding:'1rem',
        boxSizing:'border-box',
        '& img':{
          width:'100%'
        }
      }
    },

    joinIdeaTeamContent:{

      borderBottom:"2px dashed #333",
      paddingBottom:'3rem',
      fontSize: "16px",
      fontFamily: "VodafoneRg-Regular",
      '& .block':{
        marginBottom:'1.25rem',
        '& h3':{
          fontSize: "18px",
          fontFamily: "VodafoneRg-Bold",
          margin:'0',
        },
        "& p":{
          margin:'0.8rem 0 0.8rem'
        },

      }
    },

    userStats:{
      borderBottom:"2px dashed #333",
      fontSize: "16px",
      fontFamily: "VodafoneRg-Regular",
      paddingBottom:'3rem',
      marginBottom:'3rem',

      '& .userStatsBlock':{

      padding: '0.8rem',
      'box-sizing': 'border-box',
        width: '120px',
        height: '120px',
        boxShadow: '0px 1px 3px #ccc',
        borderRadius: '1rem',
        background: '#fff',
        textAlign:'center',
        display: 'flex',
        
        'align-items': 'center',
        'justify-content': 'center',
        'flex-direction': 'column',
        '& p':{
          margin:'0 0 0.8rem',
          fontSize:'1rem',
          fontFamily: "VodafoneRg-Regular",
        },
        '& h3':{
          margin:'0.8rem 0 0',
          fontSize:'0.9rem',
          fontFamily: "VodafoneRg-bold",
        }
      },
      '& .alignStats':{
        display: 'flex',
        gap:'1rem',
        'flex-wrap':'wrap',
      }
    },

    badgesItem:{
      borderBottom:"2px dashed #333",
      fontSize: "16px",
      fontFamily: "VodafoneRg-Regular",
      paddingBottom:'3rem',
      marginBottom:'3rem',
      '& .alignbadges':{
        display: 'flex',
        gap:'1rem',
        'flex-wrap':'wrap',
      },
      '& .badgesItem':{
        textAlign:'center',
        '& img':{
          width:'70px'
        },
        '& h3':{
          fontSize:'0.9rem',
          fontFamily: "VodafoneRg-bold",
        }
      }

    }


  })
  
  export default styles
  

